import * as React from "react";

function RunLocallyPanel() {
  return (
    <div className="panel panel-primary">
      <div className="panel panel-heading">
        <h3 className="panel-title">Should I trust this?</h3>
      </div>
      <div className="panel-body">
        <p>Great question! A few items to consider...</p>
        <ul>
          <li><strong>There is no backend here</strong> - it's <em>only</em> static HTML/JavaScript/CSS</li>
          <li><strong>Only talks to Middleware services</strong> - watch the network traffic if you want to be sure</li>
          <li><strong>Source code is available</strong> - the repo is linked in the top-nav bar</li>
        </ul>
        <p>If you're still worried, this app is a Docker container, so you can run it yourself too!</p>
        <ol>
          <li>
            Run the following command:<br />
            <code>docker container run -dp 443:443 code.vt.edu:5005/devcom/ed-jwt-generator</code>
          </li>
          <li>And then open your browser to <a href="https://localhost">https://localhost/</a> (yes... it uses a self-signed cert)</li>
        </ol>

        <p>If you want to be extra nerdy, you can add this alias and then simply run <code>get-jwt</code>:</p>
        <pre onClick={(event) => getSelection().selectAllChildren(event.target) }>alias get-jwt='docker container run -dtip 443:443 --name jwt_gen code.vt.edu:5005/devcom/ed-jwt-generator; open https://localhost; docker container attach jwt_gen; docker container rm -f jwt_gen'</pre>
      </div>
    </div>
  );
}

export default RunLocallyPanel;
