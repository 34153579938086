
export const EVENT_SET_PID = "setPid";
export const EVENT_SET_PASSWORD = "setPassword";
export const EVENT_SET_2FA = "set2Fa";
export const EVENT_SET_FORM_COMPLETENESS = "setFormCompleteness";
export const REQUEST_JWT = "requestJwt";
export const EVENT_SET_JWT = "setJwt";
export const EVENT_TOGGLE_ADVANCED = "toggleAdvanced";
export const EVENT_SET_ENVIRONMENT = "setEnvironment";
export const EVENT_JWT_FETCH_ERROR = "jwtFetchError";
export const REVOKE_TOKEN = "revokeToken";
export const TOKEN_REVOKED = "tokenRevoked";
