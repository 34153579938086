import {REQUEST_JWT} from "../constants";
import 'whatwg-fetch';
import jwtDecode from "jwt-decode";
import setJwt from "../actions/setJwt";
import jwtFetchError from "../actions/jwtFetchError";

function checkStatus(response) {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new Error(response.statusText);
    error.response = response;
    throw error;
  }
}

const jwtFetcher = ({ getState, dispatch }) => next => action => {
  if (action.type !== REQUEST_JWT)
    return next(action);

  let form = getState().form;
  let extras = "";
  switch (form.twoFactor.type) {
    case "push":
      extras = ",push"; break;
    case "phone":
      extras = ",phone"; break;
    case "totp":
    case "hardwareToken":
      extras = `,${form.twoFactor.value}`; break;
    default:
  }

  const authToken = btoa(`${form.pid}:${form.password}${extras}`);

  fetch(`https://${form.environment}.middleware.vt.edu/ws/v2/accounts/tokens`, {
      method : "POST",
      headers : {
        "Authorization": `Basic ${authToken}`,
      },
    })
    .then(checkStatus)
    .then((response) => response.text())
    .then((jwt) => processJwt(jwt))
    .catch((err) => dispatch(jwtFetchError(err.message)));

  function processJwt(jwt) {
    const decoded = jwtDecode(jwt);
    dispatch(setJwt(jwt, decoded));
  }

  next(action);
};

export default jwtFetcher;
