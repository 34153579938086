import React, { useState, useCallback } from "react";
import JwtForm from "./JwtForm";
import {connect} from "react-redux";
import actions from "../actions";

const LS_SHOW_ADVANCED_PANEL_KEY = "ed-jwt.devcom:showAdvancedPanel";
const defaultShowAdvanced = (localStorage.getItem(LS_SHOW_ADVANCED_PANEL_KEY) !== null) ?
  JSON.parse(localStorage.getItem(LS_SHOW_ADVANCED_PANEL_KEY)) : false;

function JwtRequestPanel({ jwt }) {
  const [showingAdvanced, setShowAdvanced] = useState(defaultShowAdvanced);

  const toggleAdvanced = useCallback(() => {
    setShowAdvanced(advanced => {
      localStorage.setItem(LS_SHOW_ADVANCED_PANEL_KEY, JSON.stringify(!advanced));
      return !advanced;
    });
  }, [setShowAdvanced]);

  if (jwt && jwt.token)
    return null;

  return (
    <div className="panel panel-primary">
      <div className="panel panel-heading">
        <div className="pull-right">
          { showingAdvanced && (
            <button className="btn btn-link" onClick={toggleAdvanced}><small>Hide Advanced</small></button>
          )}

          { !showingAdvanced && (
            <button className={"btn btn-link"} onClick={toggleAdvanced}><small>Show Advanced</small></button>
          )}
        </div>
        <h2 className="panel-title">
          Request a JWT!
        </h2>
      </div>
      <div className="panel-body">
        <p>Use the form below to generate a JWT that can be used to login to various systems.</p>

        <JwtForm showAdvanced={showingAdvanced} />
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  jwt : state.jwt,
});

export default connect(mapStateToProps, actions)(JwtRequestPanel);
