import {applyMiddleware} from "redux";
import formValidator from "./formValidator";
import jwtFetcher from "./jwtFetcher";
import tokenRevoker from "./tokenRevoker";

export const middleware = applyMiddleware(
  formValidator,
  jwtFetcher,
  tokenRevoker,
);
