import React from 'react';
import './App.css';
import {Provider} from "react-redux";
import store from "./store";
import Header from "./components/Header";
import JwtRequestPanel from "./components/JwtRequestPanel";
import JwtDisplayPanel from "./components/JwtDisplayPanel";
import RunLocallyPanel from "./components/RunLocallyPanel";
import Footer from "./components/Footer";

function App() {
  return (
    <Provider store={store}>
      <div className="app">
        <Header />

        <div className="container">
          <div className="col-md-8 col-md-push-2">
            <JwtRequestPanel />

            <JwtDisplayPanel />

            <div style={{marginTop:"55px"}}>
              <RunLocallyPanel />
            </div>

            <Footer />
          </div>
        </div>
      </div>
    </Provider>
  );
}

export default App;
