import * as React from "react";

function ViewSourceLink({ url }) {
  return (
    <a href={url}>
      <img alt={"GitLab Logo"} src="/gitlab.png" style={{height: "16px", marginTop: "-4px"}} />&nbsp;
      View Source
    </a>
  );
}

export default ViewSourceLink;
