import React, { useCallback, useRef, useState } from "react";
import * as PropTypes from "prop-types";

function CopyInputGroup({ value }) {

  const [copied, setCopied] = useState(false);

  const inputEl = useRef(null);

  const copyMessage = useCallback(() => {
    inputEl.current.select();
    inputEl.current.setSelectionRange(0, 99999);
    document.execCommand("copy");

    setCopied(true);
    setTimeout(() => setCopied(false), 2000);
  }, [value, inputEl]);

  return (
      <div className="input-group">
        <input ref={inputEl} value={value} className={"form-control disabled"} readOnly={true} />
        <span className={"input-group-btn"}>
          { copied ? (
            <button className="btn btn-default" type="button">
              <i className={"fa fa-check text-success"} />
            </button>
          ) : (
            <button className="btn btn-default" type="button" onClick={copyMessage}>
              <i className={"fa fa-clipboard"} />
            </button>
          )}
        </span>
      </div>
  );
}

CopyInputGroup.propTypes = {};

export default CopyInputGroup;
