import * as React from "react";

function EnvironmentPicker({ onSelection, environment }) {

  const onChange = (event) => {
    onSelection(event.target.value);
  };

  return (
    <div className="form-group">
      <label className="control-label col-md-3" htmlFor="pid">Environment:</label>
      <div className="col-md-6">
        <div className="radio">
          <label>
            <input type="radio" name="env" value="apps" checked={environment === "apps"} onChange={onChange} /> Prod
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="env" value="apps-pprd" checked={environment === "apps-pprd"} onChange={onChange} /> Pre-prod
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="env" value="apps-dev" checked={environment === "apps-dev"} onChange={onChange} /> Dev
          </label>
        </div>
      </div>
    </div>
  );
}

export default EnvironmentPicker;
