import {EVENT_SET_PASSWORD} from "../constants";

const setPassword = (password) => ({
  type : EVENT_SET_PASSWORD,
  payload : password,
  meta : {
    debounce : "simple",
  },
});

export default setPassword;
