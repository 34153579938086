import {compose, createStore} from "redux";
import {reducers} from "./reducers";
import {middleware} from "./middleware";

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(reducers, composeEnhancers(middleware));

store.dispatch({ type : "APP_INIT" });

export default store;
