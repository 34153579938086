import {REVOKE_TOKEN} from "../constants";
import 'whatwg-fetch';
import tokenRevoked from "../actions/tokenRevoked";

const tokenRevoker = ({ getState, dispatch }) => next => action => {
  if (action.type !== REVOKE_TOKEN)
    return next(action);

  const { jwt, form } = getState();

  fetch(`https://${form.environment}.middleware.vt.edu/ws/v2/accounts/tokens`, {
      method : "DELETE",
      headers : {
        "Authorization": `Bearer ${jwt.token}`,
      },
    })
    .then(() => dispatch(tokenRevoked()));

  next(action);
};

export default tokenRevoker;
