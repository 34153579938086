import {EVENT_SET_2FA, EVENT_SET_PASSWORD, EVENT_SET_PID} from "../constants";
import setFormCompleteness from "../actions/setFormCompleteness";

const formValidator = ({ getState, dispatch }) => next => action => {
  if (action.type !== EVENT_SET_PID && action.type !== EVENT_SET_PASSWORD && action.type !== EVENT_SET_2FA)
    return next(action);

  next(action);

  const form = getState().form;

  const formIsValid = (form.pid.length > 0 && form.password.length > 0 && form.twoFactor.type &&
    (
      (form.twoFactor.type !== "totp" && form.twoFactor.type !== "hardwareToken") || (form.twoFactor.value !== undefined)
    )
  );

  dispatch(setFormCompleteness(formIsValid));
};

export default formValidator;
