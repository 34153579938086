import React, { useEffect, useState } from "react";

function Footer() {
  const [config, setConfig] = useState(null);

  useEffect(() => {
    fetch("/config.json")
      .then(response => response.json())
      .then(setConfig);
  }, []);

  return (
      <div className={"text-center text-muted"} style={{marginBottom:"30px"}}>
        { config && (
          <>
            Commit: { config.commit }&nbsp;|&nbsp;
            Pipeline: { config.build.pipeline }&nbsp;|&nbsp;
            Job: { config.build.job }
          </>
        )}
      </div>
  );
}

Footer.propTypes = {};

export default Footer;
