import * as React from "react";

function PidEntry({ onEntry }) {

  const onChange = (event) => {
    onEntry(event.target.value);
  };

  return (
    <div className="form-group">
      <label className="control-label col-md-3" htmlFor="vt-username">VT Username:</label>
      <div className="col-md-6">
        <input type="text" name="vt-username" id="vt-username" className="form-control" onChange={onChange} />
      </div>
    </div>
  );
}

export default PidEntry;
