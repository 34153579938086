import * as React from "react";
import TwoFactorSelection from "./TwoFactorSelection";
import PidEntry from "./PidEntry";
import PasswordEntry from "./PasswordEntry";
import {connect} from "react-redux";
import actions from "../actions";
import EnvironmentPicker from "./EnvironmentPicker";

function JwtForm({ showAdvanced, requestJwt, setPid, setPassword, set2Fa, complete, fetching, environment, setEnvironment, fetchError }) {

  const onSubmit = (event) => {
    event.preventDefault();
    requestJwt();
  };

  return (
    <form className="form-horizontal" onSubmit={onSubmit}>

      <PidEntry onEntry={setPid} />
      <PasswordEntry onEntry={setPassword} />
      <TwoFactorSelection onSelection={set2Fa} />

      { showAdvanced && (
        <EnvironmentPicker environment={environment} onSelection={setEnvironment} />
      )}

      <div className="col-md-6 col-md-push-3">

        <div className={"alert alert-info"}>
          Note: You must be on-campus or use the VT Pulse VPN to request a token.
        </div>

        { fetching && (
          <p><button className="btn btn-primary" disabled><i className="fa fa-spin fa-circle-o-notch" />&nbsp;Fetching JWT...</button></p>
        )}

        { !fetching && (
          <p><button className="btn btn-primary" disabled={!complete}>Get JWT!</button></p>
        )}

        { fetchError && (
          <div className="alert alert-danger">An error occurred while trying to fetch your JWT: {fetchError} <hr />This <em>could</em> indicate either invalid credentials or that you are making the request using a non-VT address.</div>
        )}
      </div>

    </form>
  );
}

const mapStateToProps = (state) => ({
  complete : state.form.complete,
  fetching : state.form.fetching,
  environment : state.form.environment,
  fetchError : state.form.fetchError,
});
const mapDispatchToProps = actions;

export default connect(mapStateToProps, mapDispatchToProps)(JwtForm);
