import * as React from "react";
import ViewSourceLink from "./ViewSourceLink";

function Header() {
  return (
    <header className="navbar navbar-default" id="main-header">
      <div className="container">
        <div className="navbar-header">
          <a href="/" className="navbar-brand">
            <img alt="VT Logo" src="/vt_new_white_transparent.svg" style={{height : "55px", marginTop: "-15px", float: "left", paddingRight: "8px"}} />
            ED JWT Generator
          </a>
        </div>
        <ul className="nav navbar-nav navbar-right">
          <li>
            <ViewSourceLink url="https://code.vt.edu/devcom/ed-jwt-generator" />
          </li>
        </ul>
      </div>
    </header>
  );
}

export default Header;
