import setPid from "./setPid";
import setPassword from "./setPassword";
import set2Fa from "./set2Fa";
import requestJwt from "./requestJwt";
import setEnvironment from "./setEnvironment";
import revokeToken from "./revokeToken";

const actions = {
  setPid,
  setPassword,
  set2Fa,
  requestJwt,
  setEnvironment,
  revokeToken,
};

export default actions;
