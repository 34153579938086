import {EVENT_SET_PID} from "../constants";

const setPid = (pid) => ({
  type : EVENT_SET_PID,
  payload : pid,
  meta : {
    debounce : "simple",
  },
});

export default setPid;
