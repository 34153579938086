import {
  EVENT_JWT_FETCH_ERROR,
  EVENT_SET_2FA, EVENT_SET_ENVIRONMENT, EVENT_SET_FORM_COMPLETENESS, EVENT_SET_JWT, EVENT_SET_PASSWORD, EVENT_SET_PID,
  REQUEST_JWT
} from "../constants";

const defaultState = {
  pid : "",
  password : "",
  twoFactor : { type : null },
  environment : "apps",
  complete : false,
  fetching : false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EVENT_SET_PID:
      return Object.assign({}, state, { pid: action.payload });
    case EVENT_SET_PASSWORD:
      return Object.assign({}, state, { password: action.payload });
    case EVENT_SET_2FA:
      return Object.assign({}, state, { twoFactor: action.payload });
    case EVENT_SET_FORM_COMPLETENESS:
      return Object.assign({}, state, { complete : action.payload });
    case REQUEST_JWT:
      return Object.assign({}, state, { fetching : true, fetchError : null, });
    case EVENT_SET_JWT:
      return Object.assign({}, state, { fetching : false, });
    case EVENT_SET_ENVIRONMENT:
      return Object.assign({}, state, { environment : action.payload });
    case EVENT_JWT_FETCH_ERROR:
      return Object.assign({}, state, { fetching : false, fetchError : action.payload.message });
    default:
      return state;
  }
};
