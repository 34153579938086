import * as React from "react";
import {connect} from "react-redux";
import actions from "../actions";
import CopyInputGroup from "./CopyInputGroup";

function JwtDisplayPanel({ revokeToken, jwt }) {

  if (!jwt || !jwt.token)
    return ( <div /> );

  return (
    <div className="panel panel-success">
      <div className="panel-heading">
        <h2 className="panel-title">JWT Results</h2>
      </div>
      <div className="panel-body">
        <p>Your request completed successfully!</p>

        <form className={"form-horizontal"}>
          <div className="form-group">
            <label className="col-sm-3 control-label">Subscriber</label>
            <div className="col-sm-9">
              <p className="form-control-static">{ jwt.sub }</p>
            </div>
          </div>

          <div className="form-group">
            <label className={"col-md-3 control-label"}>Password</label>
            <div className="col-sm-9">
              <CopyInputGroup value={jwt.password} />
            </div>
          </div>

          <div className="form-group">
            <label className={"col-md-3 control-label"}>Full JWT Token</label>
            <div className="col-sm-9">
              <CopyInputGroup value={jwt.token} />
            </div>
          </div>
        </form>

        <div className="col-sm-4 col-sm-offset-4">
          { jwt.revoking ? (
            <button className="btn btn-primary" disabled><i className="fa fa-spin fa-circle-o-notch" />&nbsp;Revoking token...</button>
          ) : (
            <button className="btn btn-primary" onClick={revokeToken}>Revoke token</button>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  jwt : state.jwt,
});

export default connect(mapStateToProps, actions)(JwtDisplayPanel);
