import * as React from "react";
const { useState, useEffect } = React;
function TwoFactorSelection({ onSelection }) {
  const [option, setOption] = useState({ type : "default" });

  useEffect(() => onSelection(option), [ onSelection, option ]);

  const updateState = (type, value) => {
    const state = type ? { type } : Object.assign({}, option);
    if (value) state.value = value;
    setOption(state);
  };

  const onOptionChange = (event) => {
    updateState(event.target.value, undefined);
  };

  const onTotpEntry = (event) => {
    const value = event.target.value;
    if (value.length !== 6)
      return;
    updateState(undefined, value);
  };

  const onTokenEntry = (event) => {
    updateState(undefined, event.target.value);
  };

  return (
    <div className="form-group">
      <label className="control-label col-md-3">Two-factor option:</label>
      <div className="col-md-9">
        <div className="radio">
          <label>
            <input type="radio" name="2faOption" value="default" onClick={onOptionChange} checked={option.type === "default"} /> Default setting <small>(push or phone)</small>
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="2faOption" value="push" onClick={onOptionChange} /> Push notification<br />
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="2faOption" value="phone" onClick={onOptionChange} /> Phone call<br />
          </label>
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="2faOption" value="totp" onClick={onOptionChange} /> TOTP Passcode <small>(6-digit code)</small><br />
          </label>
          { option.type === "totp" && (
            <div className="additional-info">
              <div className="form-group">
                <label className="col-md-4 control-label">TOTP Passcode:</label>
                <div className="col-md-6">
                  <input type="text" name="totpCode" className="form-control" autoComplete="off" onChange={onTotpEntry} />
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="radio">
          <label>
            <input type="radio" name="2faOption" value="hardwareToken" onClick={onOptionChange} /> Hardware Token<br />
          </label>
          { option.type === "hardwareToken" && (
            <div className="additional-info">
              <div className="form-group">
                <label className="col-md-4 control-label">Token value:</label>
                <div className="col-md-6">
                  <input type="text" name="tokenValue" className="form-control" autoComplete="off" onChange={onTokenEntry} />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default TwoFactorSelection;
