import {EVENT_SET_JWT, REVOKE_TOKEN, TOKEN_REVOKED} from "../constants";

const defaultState = {
  token : null,
  sub : null,
  password : null,
  iss : null,
  exp : null,
  iat : null,
  revoking : false,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case EVENT_SET_JWT:
      return Object.assign({}, state, action.payload);
    case REVOKE_TOKEN:
      return Object.assign({}, state, { revoking : true });
    case TOKEN_REVOKED:
      return Object.assign({}, defaultState);
    default:
      return state;
  }
};
